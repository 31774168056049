<template>
  <b-form-select @change="handleChange" v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25">
  </b-form-select>
</template>
<script>
import perPageData from '@/views/shared-data/perPageData.json'
export default {
  data() {
    return {
      // pageOptions: [20, 50, 100, 200],
      perPage: this.xml ? 200 : 50,
      data: perPageData,
      focused: false,
    }
  },
  props: [
    // 'perPage',
    'pageOptions',
    'storageKey',
    'value',
    'xml',
  ],
  methods: {
    handleChange() {
      this.data[this.storageKey] = this.perPage
      window.localStorage.setItem('per-page-data', JSON.stringify(this.data))
      this.$emit('input', this.perPage)
    },
  },
  // created() {
  //   console.log(this.value, 'value')
  // },
  mounted() {
    this.perPage = JSON.parse(window.localStorage.getItem('per-page-data'))[this.storageKey]
    if (!this.perPage) {
      const localPerPageData = JSON.parse(window.localStorage.getItem('per-page-data'))
      localPerPageData[this.storageKey] = this.xml ? 200 : 50
      window.localStorage.setItem('per-page-data', JSON.stringify(localPerPageData))
      this.perPage = this.xml ? 200 : 50
    }
    this.$emit('input', this.perPage)
  },
  watch: {
    perPage(val) {
      this.data[this.storageKey] = val
    },
  },
}
</script>
