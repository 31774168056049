const campaignColumns = [
  {
    key: 'DateStats',
    label: 'Date',
    show: true,
    headerTitle: 'Date',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'PublisherId',
    label: 'Publisher ID',
    show: false,
    headerTitle: 'Publisher ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'PublisherName',
    label: 'Publisher Name',
    show: true,
    headerTitle: 'Publisher Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'BannerzoneId',
    label: 'Banner Zone ID',
    show: false,
    headerTitle: 'Banner Zone ID',
    sortable: true,
    thStyle: 'width:130px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'BannerzoneName',
    label: 'Banner Zone Name',
    show: true,
    headerTitle: 'Banner Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'VideozoneId',
    label: 'Video Zone ID',
    show: false,
    headerTitle: 'Video Zone ID',
    sortable: true,
    thStyle: 'width:120px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'VideozoneName',
    label: 'Video Zone Name',
    show: true,
    headerTitle: 'Video Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RtbzoneId',
    label: 'RTB Zone ID',
    show: true,
    headerTitle: 'RTB Zone ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RtbzoneName',
    label: 'RTB Zone Name',
    show: true,
    headerTitle: 'RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'PopfeedId',
    label: 'Pop Feed ID',
    show: false,
    headerTitle: 'Pop Feed ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
  },
  {
    key: 'PopfeedName',
    label: 'Pop Feed Name',
    show: false,
    headerTitle: 'Pop Feed Name',
    sortable: false,
  },
  {
    key: 'AppBundle',
    label: 'App Bundle',
    show: true,
    headerTitle: 'App Bundle',
    sortable: true,
  },
  {
    key: 'Site',
    label: 'Site',
    show: true,
    headerTitle: 'Site',
    sortable: true,
  },
  {
    key: 'PubId',
    label: 'Pub ID',
    show: true,
    headerTitle: 'Pub ID',
    sortable: true,
  },
  {
    key: 'Error',
    label: 'Error',
    show: true,
    headerTitle: 'Error',
    sortable: true,
    thStyle: 'width: 140px',
    class: 'text-center',
    summed: false,
  },
  {
    key: 'Ivt',
    label: 'IVT',
    show: true,
    headerTitle: 'IVT',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:80px',
  },
  {
    key: 'Sivt',
    label: 'SIVT',
    show: true,
    headerTitle: 'SIVT',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:80px',
  },
  {
    key: 'Requests',
    label: 'Requests',
    show: true,
    headerTitle: 'Requests',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'Frauds',
    label: 'Frauds',
    show: true,
    headerTitle: 'Frauds',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Checks',
    label: 'Checks',
    show: true,
    headerTitle: 'Checks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'FraudsPercentage',
    label: 'Frauds %',
    show: true,
    headerTitle: 'Frauds %',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:90px',
    summed: true,
  },
]
export default campaignColumns
